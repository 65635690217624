import { useContext } from "react";
import Context from "../context/Context";
import { formatCurrency } from "../utils/formatCurrency";

export const useOrdersData = () => {
  const { orderData, selectorData, isCheckoutApiVersion2 } = useContext(
    Context
  );
  return isCheckoutApiVersion2
    ? getOrdersDataV2(orderData)
    : getOrdersDataV1(orderData, selectorData);
};

const getOrdersDataV1 = (orderData, selectorData) => {
  const { currency } = selectorData;
  const { items = [], products = [] } = orderData || {};

  const orderItems = [...items].map((orderItem, index) => {
    return {
      ...orderItem,
      productName: products.at(index).product_name,
      sku: products.at(index).SKU,
      confirmed: true,
      discountedPrice: formatCurrency({
        currency: currency?.code,
        amount: orderItem.discounted_price
      }),
      freeGiftValue: formatCurrency({
        currency: currency?.code,
        amount: orderItem.free_gift_value
      }),
      perceivedRrp: formatCurrency({
        currency: currency?.code,
        amount: orderItem.perceived_rrp
      }),
      schedule: orderItem.sub_frequency
    };
  });

  return {
    orderItems,
    hasItems: orderItems.length > 0,
    subTotal: formatCurrency({
      currency: currency?.code,
      amount: orderData?.cart_total_before_discounts || 0
    }),
    orderTotal: formatCurrency({
      currency: currency?.code,
      amount: orderData?.checkout_total || 0
    }),
    shipping: formatCurrency({
      currency: currency?.code,
      amount: orderData?.shipping
    }),
    isFreeShipping: orderData?.shipping === 0,
    savings: formatCurrency({
      currency: currency?.code,
      amount: orderData?.checkout_discount_amount
    }),
    taxes: formatCurrency({ currency: currency?.code, amount: orderData?.tax }),
    currency: currency?.symbol,
    cart_total_before_discounts: orderData?.cart_total_before_discounts,
    cart_total_with_discounts: orderData?.cart_total_with_discounts,
    cart_total_with_discounts_with_taxes: orderData?.checkout_total,
    cart_total_with_discounts_before_taxes:
      orderData?.cart_total_with_discounts,
    cart_total_savings: orderData?.checkout_discount_amount,
    customer: orderData?.customer,
    checkout_total: orderData?.checkout_total,
    taxes_raw: orderData?.tax,
    tax_rate: orderData?.tax / orderData?.cart_total_with_discounts,
    checkout_discount_amount: orderData?.checkout_discount_amount,
    customer_first_name: orderData?.customer?.first_name,
    shipping_raw: orderData?.shipping,
    customer_email: orderData?.customer?.email
  };
};

const getOrdersDataV2 = orderData => {
  const { line_items = [], currency } = orderData || {};
  const orderItems = [...line_items].map(orderItem => {
    return {
      ...orderItem,
      product_image: orderItem.image_url,
      productName: orderItem.product_name,
      confirmed: true,
      discounted_price: orderItem.pricing.discounted_line_item_price,
      discountedPrice: formatCurrency({
        currency: currency?.code,
        amount: orderItem.pricing.discounted_line_item_price
      }),
      freeGiftValue: formatCurrency({
        currency: currency?.code,
        amount: orderItem.pricing.line_item_price
      }),
      perceivedRrp: formatCurrency({
        currency: currency?.code,
        amount: orderItem.pricing.line_item_price
      }),
      isFreeGift: orderItem.pricing.is_free,
      is_subscription: orderItem.schedule !== null && orderItem.schedule !== "",
      sub_frequency: orderItem.schedule
    };
  });

  return {
    orderItems,
    hasItems: orderItems.length > 0,
    subTotal: formatCurrency({
      currency: currency?.code,
      amount: orderData?.pricing?.subtotal || 0
    }),
    orderTotal: formatCurrency({
      currency: currency?.code,
      amount: orderData?.pricing?.order_total || 0
    }),
    shipping: formatCurrency({
      currency: currency?.code,
      amount: orderData?.shipping?.shipping_fee || 0
    }),
    isFreeShipping: orderData?.pricing?.has_free_shipping,
    savings: formatCurrency({
      currency: currency?.code,
      amount: orderData?.pricing?.total_savings || 0
    }),
    taxes: formatCurrency({
      currency: currency?.code,
      amount: orderData?.pricing?.taxes || 0
    }),
    currency: currency?.symbol,
    cart_total_before_discounts: orderData?.pricing?.subtotal,
    cart_total_with_discounts: orderData?.pricing?.discounted_subtotal,
    cart_total_with_discounts_with_taxes: orderData?.order_total,
    cart_total_with_discounts_before_taxes:
      orderData?.pricing?.discounted_subtotal,
    cart_total_savings: orderData?.pricing?.total_savings,
    customer: orderData?.shipping_address,
    checkout_total: orderData?.pricing?.order_total,
    taxes_raw: orderData?.pricing?.taxes,
    tax_rate: orderData?.pricing?.tax_rate,
    checkout_discount_amount: orderData?.pricing?.total_savings,
    customer_first_name: orderData?.shipping_address?.first_name,
    shipping_raw: orderData?.shipping?.shipping_fee,
    customer_email: orderData?.email
  };
};
