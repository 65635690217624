import styled from "styled-components";

export const ToggleContainer = styled.div`
  cursor: pointer;
`;

export const SummaryWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 30%;
  height: 100vh;
  width: 100%;
  z-index: 998;
`;

export const SummaryContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: linear-gradient(180deg, #fff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  height: 100vh;
  max-width: 535px;
  width: 100%;
  z-index: 999;
  padding: 60px 48px;
  border-left: 1px solid #d9d9d9;
  @media screen and (max-width: 1024px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 56px;
    padding-bottom: 40px;
    max-width: 435px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 56px;
    padding-bottom: 40px;
    position: absolute;
    left: 0;
    width: calc(100% - 32px);
    border: none;
    height: auto;
    bottom: 0;
    top: inherit;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

export const Cancel = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`;
export const CancelImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const Items = styled.div`
  font-family: "Neuzeit Grotesk", sans-serif;
`;

export const Item = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
`;

export const ItemTitle = styled.div``;

export const ItemImageWrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 65px;
  height: 65px;
  position: relative;
`;

export const ItemImage = styled.img`
  width: 65px;
  height: 65px;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const ItemDetailsInner = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
`;

export const ItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
export const ItemQuantity = styled.div`
  position: absolute;
  z-index: 100;
  right: -10px;
  top: -10px;
  border-radius: 100%;
  background: #333;
  color: #fff;
  padding: 3px;
  line-height: 18px;
  width: 17px;
  height: 17px;
  opacity: 0.8;
  text-align: center;
  font-size: 14px;
`;
export const ItemPricesInner = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
`;
export const ItemPrices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`;
export const ItemPricesDP = styled.div`
  color: #333;
  text-align: right;
  font-family: "Neuzeit Grotesk - Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 140%;
`;
export const ItemPricesRRP = styled.div`
  color: #333;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: line-through;
`;
export const ItemFrequency = styled.div`
  & > strong {
    font-family: "Neuzeit Grotesk - Bold", sans-serif;
  }
`;

export const TotalsBorder = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

export const Totals = styled.div`
  font-family: "Neuzeit Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;
export const TotalLabel = styled.div``;
export const TotalValue = styled.div`
  &.grand-total {
    color: #333;
    text-align: right;
    font-family: "Neuzeit Grotesk - bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;
export const SubTotals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const SubTotalItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const GrandTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333;
  font-size: 18px;
  line-height: 140%;
  font-family: "Neuzeit Grotesk - Bold", sans-serif;
`;
export const SavingsMessage = styled.div`
  color: #118611;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  font-family: "Neuzeit Grotesk", sans-serif;
`;
