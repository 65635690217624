import React, { useContext } from "react";
import * as S from "./OrderSummary.styles";
import Context from "../../context/Context";
import Modal from "../Modal/Modal";
import { useOrdersData } from "../../hooks/useOrdersData";
import { frequencyLabels } from "../../utils/constants/frequency";

const OrderSummary = () => {
  const { showOrderSummary, setShowOrderSummary } = useContext(Context);

  const {
    orderItems,
    currency,
    subTotal,
    orderTotal,
    isFreeShipping,
    savings,
    taxes
  } = useOrdersData();

  const onClickHandler = () => {
    setShowOrderSummary(false);
  };

  return showOrderSummary ? (
    <Modal>
      <S.SummaryWrapper onClick={onClickHandler} />
      <S.SummaryContainer>
        <S.Cancel onClick={onClickHandler}>
          <S.CancelImage src="/images/cancel.svg" />
        </S.Cancel>
        {orderItems?.length > 0 && (
          <>
            <S.Items>
              {orderItems.map((item, index) => (
                <S.Item key={index}>
                  <S.ItemDetailsWrapper>
                    <S.ItemImageWrapper>
                      <S.ItemQuantity>{item?.quantity}</S.ItemQuantity>
                      <S.ItemImage src={item?.product_image} />
                    </S.ItemImageWrapper>
                    <S.ItemDetails>
                      <S.ItemDetailsInner>
                        <S.ItemTitle>{item?.productName}</S.ItemTitle>
                        {item?.schedule ? (
                          <S.ItemFrequency>
                            <strong>Frequency:</strong>{" "}
                            {frequencyLabels[item?.schedule]}
                          </S.ItemFrequency>
                        ) : null}
                      </S.ItemDetailsInner>
                    </S.ItemDetails>
                  </S.ItemDetailsWrapper>
                  <S.ItemPrices>
                    <S.ItemPricesInner>
                      <S.ItemPricesRRP>
                        {item?.discounted_price
                          ? `${item?.perceivedRrp}`
                          : `${item?.freeGiftValue}`}
                      </S.ItemPricesRRP>
                      <S.ItemPricesDP>
                        {item?.discounted_price
                          ? `${item?.discountedPrice}`
                          : `FREE`}
                      </S.ItemPricesDP>
                    </S.ItemPricesInner>
                  </S.ItemPrices>
                </S.Item>
              ))}
            </S.Items>
            <S.Totals>
              <S.SubTotals>
                <S.SubTotalItem>
                  <S.TotalLabel>Subtotal</S.TotalLabel>
                  <S.TotalValue>{subTotal}</S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>Shipping</S.TotalLabel>
                  <S.TotalValue>
                    {isFreeShipping ? (
                      <>
                        <s>{currency}4.99</s> FREE
                      </>
                    ) : (
                      <>{currency}4.99</>
                    )}
                  </S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>Taxes</S.TotalLabel>
                  <S.TotalValue>{taxes}</S.TotalValue>
                </S.SubTotalItem>
                <S.SubTotalItem>
                  <S.TotalLabel>Total Savings</S.TotalLabel>
                  <S.TotalValue>-{savings}</S.TotalValue>
                </S.SubTotalItem>
              </S.SubTotals>
              <S.TotalsBorder />
              <S.GrandTotal>
                <S.TotalLabel>Order Total</S.TotalLabel>
                <S.TotalValue className="grand-total">
                  {orderTotal}
                </S.TotalValue>
              </S.GrandTotal>
              <S.SavingsMessage>
                Your discount has successfully been applied!
                <br />
                You’ve saved {savings}
              </S.SavingsMessage>
            </S.Totals>
          </>
        )}
      </S.SummaryContainer>
    </Modal>
  ) : null;
};

export default OrderSummary;
