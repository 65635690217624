export const formatCurrency = ({ locale = "en-US", currency, amount }) => {
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency || "USD",
      currencyDisplay: "narrowSymbol"
    }).format(amount);
  } catch (error) {
    console.error("Error formatting currency:", error);
    return amount.toString(); // Fallback to returning the raw number as a string
  }
};
