import { useEffect } from "react";
import ReactDOM from "react-dom";

const Modal = props => {
  const { children } = props;
  const el = document.createElement("div");
  const parent = document.getElementById("___gatsby");
  const gatsbyFocusWrapper = document.getElementById("gatsby-focus-wrapper");
  useEffect(() => {
    parent.appendChild(el);
    if (gatsbyFocusWrapper.clientWidth < 640) {
      gatsbyFocusWrapper.style.display = "none";
    }
    return () => {
      parent.removeChild(el);
      gatsbyFocusWrapper.style.display = "block";
    };
  }, [el, parent, gatsbyFocusWrapper]);
  return ReactDOM.createPortal(children, el);
};

export default Modal;
